<script>
import {
  BCard,
  BModal,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import CommonServices from "@/apiServices/CommonServices";
import LmsServices from "@/apiServices/LmsServices";
// import grapesjs from 'grapesjs';
import htmlWithCssPlugin from 'grapesjs-preset-newsletter';
import grapesjs from "grapesjs/dist/grapes.min";
import 'grapesjs/dist/css/grapes.min.css';
import { emailVariables } from "@/views/pages/MyLeads/constants";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormTextarea,
    vSelect
  },

  data() {
    return {
      emailVariables,
      email_templates: [],
      showEmailModal: false,
      editor: null,
      emailForm: {
        subject: "",
        to: "",
        body: "",
        template_id: null,
        templateHtmlWithCss: "",
      },
      lmsApi: null,
      canvas: null,
    };
  },
  props: ["showModal", "to", "close", "ok"],
  methods: {
    initEditor() {
      this.editor = grapesjs.init({
        container: this.canvas,
        fromElement: true,
        storageManager: false,
        blockManager: {
          blocks: [
            // { label: "My Block", content: "<div>Hello</div>" }
          ],
        },
        canvas: {
          styles: [],
          scripts: [],
        },
        plugins: [htmlWithCssPlugin],
        // pluginsOpts: {
        //     'gjs-preset-webpage': {},
        // },
      });
    },

    async sendMail() {
      this.$props.close();
      let htmlWithCss = this.editor.runCommand('gjs-get-inlined-html');
      this.emailForm.templateHtmlWithCss = htmlWithCss;
      this.$props.ok(this.emailForm);
      this.resetEmailForm();
    },

    resetEmailForm() {
      this.emailForm = {
        subject: "",
        to: "",
        body: "",
        template_id: null,
        templateHtmlWithCss: "",
      };
    },

    handleTemplateSelect() {
      if (!this.emailForm.template_id) {
        this.editor.setComponents("");
        return
      }

      const currentTemplate = this.email_templates.find(t => t.et_id === this.emailForm.template_id)
      this.editor.setComponents(JSON.parse(currentTemplate.et_components));
      this.editor.setStyle(JSON.parse(currentTemplate.et_style));
    },

    async getAllTemplates() {
      try {
        const res = await this.lmsApi.getAllTemplates()
        console.log({ res })
        if (res.data.status) {
          this.email_templates = res.data.data.rows
        }
      } catch (error) {
        console.error(error);
      }
    },

    copyVariable(variable) {
      navigator.clipboard.writeText(`{{${variable.key}}}`);
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: `${variable.label}'s Key Copied to Clipboard`,
          icon: "Check",
          variant: "success",
        },
      });
    },

    async getLMSAccess() {
      try {
        this.isLoading = true;
        const res = await CommonServices.getLMSAccessToken();
        this.accessToken = res.data.data;

        if (!this.accessToken) {
          this.accessTokenFailed = true;
          return;
        }

        this.lmsApi = LmsServices(this.accessToken);
        await this.getAllTemplates();

      } catch (error) {
        console.error(`Error in getLMSAccess `, error);
      }
    },

  },

  watch: {
    showEmailModal(newValue) {
      if (!newValue) {
        this.resetEmailForm();
        this.$props.close();
      } else {
        console.log("here");
        this.$nextTick(() => {
          this.canvas = document.getElementById("canvas")
          this.initEditor()
        })

      }
    },

    showModal: {
      deep: true,
      handler(newValue, oldValue) {
        this.showEmailModal = this.$props.showModal;
        this.emailForm.to = this.$props.to;
      },
    },
  },

  beforeMount() {
    this.showEmailModal = this.$props.showModal;
    this.getLMSAccess()
  },
}

</script>

<template>
  <!-- Modal -->
  <b-modal v-model="showEmailModal" size="xl" hide-footer title="Send An Email">
    <form class="form gap-2 pa-5" @submit.prevent="sendMail">
      <b-row style="gap: 10px 0">
        <b-col cols="12">
          <b-form-input placeholder="Subject" label="Subject" v-model="emailForm.subject" />
        </b-col>
        <b-col cols="12">
          <b-form-textarea readonly required placeholder="To" label="To" v-model="emailForm.to" rows="3" />
        </b-col>
        <b-col cols="12">
          <v-select required placeholder="Select Template" label="et_name" :options="email_templates"
            :reduce="(item) => item.et_id" v-model="emailForm.template_id" rows="4" @input="handleTemplateSelect" />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-center my-2">
          <div class="border w-full rounded-pill d-flex"
            style="padding: 5px; gap: 5px; max-width: 50%; overflow: hidden; overflow-x: auto;">
            <b-button size="sm" class="text-nowrap" variant="primary rounded-pill" v-for="v in emailVariables"
              @click="() => copyVariable(v)" :key="v.key">
              {{ v.label }}
              <feather-icon icon="CopyIcon" size="12" stroke="#fff" />
            </b-button>
          </div>

        </b-col>
        <!-- <b-col cols="12">
          <b-form-textarea required placeholder="Message" label="Message" v-model="emailForm.body" rows="4" />
        </b-col> -->
        <b-col cols="12">
          <div id="canvas" style="height: 50vh; max-height: 50vh;"></div>
        </b-col>

        <b-col cols="12">
          <b-row class="justify-content-end pr-1" style="gap: 0 10px">
            <b-button variant="danger" @click="() => {
              showEmailModal = false;
              emailForm = {
                subject: '',
                to: '',
                body: '',
                template_id: null,
                templateHtmlWithCss: ''
              };
            }">
              Close
            </b-button>
            <b-button type="submit" variant="primary">Send Mail</b-button>
          </b-row>
        </b-col>
      </b-row>
    </form>
  </b-modal>
  <!-- End Modal -->
</template>
